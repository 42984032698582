@import "variables";

body, html {
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  touch-action: manipulation;
}

body {
  font-family: $font-family;
  color: mat-color($app-foreground, text);
  background-color: mat-color($app-background, dialog);
  line-height: 1.42857143;
  font-size: 1.4rem;
  margin: 0;
}

//box-sizing
body {
  box-sizing: border-box;

  *, *:before, *:after {
    box-sizing: border-box;
  }
}

.be-fullscreen-overlay-scrollblock {
  width: 100%;
  position: fixed;
}

.mat-form-field-flex {
    border: 1px solid #adadad;
    padding: 0 10px;
    border-radius: 5px;
    align-items: center !important;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
    color: rgb(21 101 192);
}
.mat-form-field-suffix mat-datepicker-toggle {
    color: rgb(51 122 183);
}
.mat-form-field-suffix mat-datepicker-toggle .mat-button-wrapper svg {
    width: 20px !important;
}